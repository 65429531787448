import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { CheckOutlined } from "@ant-design/icons"
import styled from "styled-components"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import LastBlogPosts from "../components/v2023/LastBlogPosts/last-blog-posts"
import Certifications from "../components/v2023/Certifications/certifications"
import Identity from "../components/v2023/Identity/identity"
import TecalisAdapts from "../components/v2023/TecalisAdapts/tecalis-adapts"


const CheckItem = styled(CheckOutlined)`
	color: purple;
`

const Industry = ({ data, pageContext }) => {

    const lang = pageContext.langKey
    const industry = data.industry
    const [characteristicSize, setCharacteristicSize] = useState(6)
    const [characteristics, setCharacteristics] = useState(industry.characteristics.slice(0, characteristicSize))

    const linkHeader = {
        "en": "https://www.tecalis.com/request-demo",
        "es": "https://www.tecalis.com/es/solicita-demo"
    }

    const showMoreCharacteristics = async () => {
        let s = characteristicSize

        if (s < industry.characteristics.length) {
            s += 6
            setCharacteristicSize(s)
            setCharacteristics(industry.characteristics.slice(0, s))
        }
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={industry.seo.title}
                description={industry.seo.meta_description}
                image={industry.seo?.image_2?.localFile?.publicURL}
                translates={pageContext.translates}
            />
            <main className="main">
                <div className="main__section main__section--100" data-aos="fade-up">
                    <div className="container">
                        <div className="tag tag--highlight">{industry.name}</div>
                        <div className="container__text">
                            <h1 className="merriweather">{industry.title}</h1>
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50--md color-section purple">
                    <div className="container">
                        <div className="container__50">
                            <ReactMarkdown
                                children={industry.description}
                                rehypePlugins={[rehypeRaw]}
                            />
                            <a href={linkHeader[pageContext.langKey]} target="_blank" className="button button--xl">
                                {industry.button_talk_export}
                            </a>
                        </div>
                        <div className="container__50">
                            <GatsbyImage
                                alt={industry.imgheader.alternativeText}
                                image={getImage(industry.imgheader.localFile)}
                                placeholder="blurred"
                                quality={100}
                            />
                        </div>
                    </div>
                </div>
                <div className="main__section">
                    <div className="container">
                        <div className="grid-sm-3">
                            {industry.facts.map((fact, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <div className="stat stat--boxed" {...animation}>
                                    <div className="title-xl">{fact.title}</div>
                                    <span>
                                        <ReactMarkdown
                                            children={fact.description}
                                            rehypePlugins={[rehypeRaw]}
                                        />
                                    </span>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile">
                            <h3>{industry.develop_title}</h3>
                            <div className="tile__body">
                                <ReactMarkdown
                                    children={industry.develop_description}
                                    rehypePlugins={[rehypeRaw]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {industry.values.map((value, index) => (
                    <div className={"main__section main__section--50 main__section--50--md" + (index % 2 == 0 ? " main__section--50-invert" : "")}  key={index}>
                        <div className="container">
                            <div className="container__50">
                                <h2>{value.title}</h2>
                                <div className="container__text">
                                    <ReactMarkdown
                                        children={value.description}
                                        rehypePlugins={[rehypeRaw]}
                                    />
                                </div>
                                <div className="link-group--vertical">
                                    {value.Link.map((item, index) => (
                                        <Link to={`${item.url}`} className="link--big" key={index}>
                                            {item.name}
                                            <i className="icon-arrow-right"></i>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            <div className="container__50">
                                <GatsbyImage alt={value.image.alternativeText} image={getImage(value.image.localFile)}
                                    placeholder="blurred"
                                    quality={100}
                                />
                            </div>
                        </div>
                    </div>
                ))}
                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{industry.how_together_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown
                                children={industry.how_together_description}
                                rehypePlugins={[rehypeRaw]}
                            />
                        </div>
                        <a href="#ready-to-talk" className="button button--xl">
                            {industry.how_together_button}
                        </a>
                        <div className="grid-lg-3">
                            {characteristics.map((character, index) => (
                                <div className="image-group" key={index}>
                                    <img src={character.icon.localFile.publicURL} alt={character.icon.alternativeText}  />
                                    <div className="image-group__text">
                                        <h5>{character.title}</h5>
                                        <ReactMarkdown children={character.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {industry.extra_value ?
                    <div className="main__section main__section--50 main__section--50--md color-section gray">
                        <div className="container">
                            <div className="container__50">
                                <h2>{industry.extra_value.title}</h2>
                                <div className="container__text">
                                    <ReactMarkdown
                                        children={industry.extra_value.description}
                                        rehypePlugins={[rehypeRaw]}
                                    />
                                </div>
                                <div className="link-group--vertical">
                                    {industry.extra_value.Link && industry.extra_value.Link.map((link, li) => (
                                        <Link key={li} to={link.url} className="button button--xl">
                                            {link.name}
                                            <i className="icon-arrow-right"></i>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            <div className="container__50" data-aos="fade-left">
                                <GatsbyImage image={getImage(industry.extra_value.image_new.localFile)} alt={industry.extra_value.image_new.alternativeText}
                                            title={industry.extra_value.title}
                                            placeholder="blurred"
                                            quality={100}
                                    />
                            </div>
                        </div>
                    </div>
                    : null
                }
                <div className="main__section main__section--50 main__section--50--md main__section--50-invert" >
                    <div className="container">
                        <div className="container__50">
                            <ReactMarkdown
                                children={industry.insights.titleinsight}
                                rehypePlugins={[rehypeRaw]}
                            />
                            <div className="container__text">
                                <ReactMarkdown
                                    children={industry.insights.descirption}
                                    rehypePlugins={[rehypeRaw]}
                                />
                            </div>
                            <ReactMarkdown
                                children={industry.insights.list}
                                rehypePlugins={[rehypeRaw]}
                            />
                        </div>
                        <div className="container__50">
                            <GatsbyImage
                                image={getImage(industry.insights.image.localFile)}
                                alt={industry.insights.image.alternativeText}
                                placeholder="blurred"
                                quality={100}
                            />
                        </div>
                    </div>
                </div>

                <TecalisAdapts lang={lang} />
                <ReadyToTalk lang={lang} />
                <LastBlogPosts lang={lang} />
                <Certifications />
                <Identity lang={lang} />
            </main>
        </NewLayout>
    )
}

export default Industry


export const indexPageQuery = graphql`
    query ($id: String!, $langKey: String!)  {
        industry: strapiIndustry (locale: {eq: $langKey}, id: {eq: $id}) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL

                    }
                }
            }
            name
            title
            button_talk_export
            description
            tag
            url
            imgheader{
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 608
                            placeholder: BLURRED
                            formats: [WEBP]
                            quality: 100
                        )
                    }
                }
            }
            industries_category {
                id
            }
            facts{
                title
                description
            }
            develop_title
            develop_description
            values{
                title
                description
                Link{
                    name
                    url
                    blank
                }
                image{
                    name
                    alternativeText
                    localFile{
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
            }
            how_together_title
            how_together_description
            how_together_button
            characteristics{
                order
                title
                description
                icon{
                    name
                    alternativeText
                    localFile {
                        publicURL
                    }
                }
            }
            characteristics_button
            extra_value{
                title
                description
                image{
                    name
                    alternativeText
                    localFile{
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
                image_new{
                    name
                    alternativeText
                    localFile{
                        childImageSharp {
                            gatsbyImageData(
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
                Link {
                    name
                    url
                    blank
                }
            }
            insights{
                title
                titleinsight
                descirption
                image{
                    name
                    alternativeText
                    localFile{
                        childImageSharp {
                            gatsbyImageData(
                                width:608
                                placeholder: BLURRED
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
                list
            }
        }
        helpers: markdownRemark (
            frontmatter: {
                lang: {
                    eq: $langKey
                }
                name: {
                    eq: "helpers"
                }
            }
        ) {
            frontmatter {
                trust_us
                last_news
                blog_path
                about_us_path
                request_demo_path
            }
        }
    }
`
